import { NgZone } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { isPlatformServer } from '@angular/common';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export class TagService {
    constructor(router, platformId, zone) {
        this.router = router;
        this.platformId = platformId;
        this.zone = zone;
        if (isPlatformServer(this.platformId)) {
            return;
        }
        const gtag = window.gtag;
        const bing = window.bing;
        router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.zone.runOutsideAngular(() => {
                    /* Google Analytics pageview event */
                    gtag('config', 'G-XHKHFSPZCF', { page_path: event.urlAfterRedirects });
                    /* Google AdWords */
                    gtag('config', 'AW-979006729');
                    console.debug('Visiting ' + event.urlAfterRedirects + ' should be tracked via GTag');
                    /* Bing Ads */
                    bing();
                });
            }
        });
    }
}
TagService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TagService_Factory() { return new TagService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i0.PLATFORM_ID), i0.ɵɵinject(i0.NgZone)); }, token: TagService, providedIn: "root" });
